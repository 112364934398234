import React, { useEffect } from "react"
import { graphql } from "gatsby"
import OpenApp from "open-app"

import Layout from "../components/layout"
import Section from "../components/section"
import { updateFavicon } from "../utils"

export const query = graphql`
  query Category($id: Int) {
    category: strapiCategory(strapi_id: { eq: $id }) {
      id
      title
      strapi_id
      sections {
        id
        title
        articles {
          id
          title
          strapi_id
        }
      }
    }
  }
`

const Category = ({ data }) => {
  useEffect(() => {
    updateFavicon()
    new OpenApp({})
    if ((window as any).imToken) {
      ;(window as any).imToken.callAPI("navigator.configure", {
        navigationStyle: "default",
      })
    }
  }, [])
  const sections = data.category.sections.sort((a, b) => a.id - b.id)
  return (
    <Layout title={data.category.title}>
      <div>
        {sections.map((t, idx) => {
          return <Section key={t.id} title={t.title} section={t} isCategory />
        })}
        <div style={{ height: 45 }} />
      </div>
    </Layout>
  )
}

export default Category
